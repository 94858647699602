import '../BlinkingText.css';
import { useTheme } from '@mui/system';
import { grey } from '@mui/material/colors';
import { Grid, Typography, Box, Button } from '@mui/material';
import ecosystemgenbanner from '../../../assets/ecosystemgenbanner.png';
import ecosystemtreasurybanner from '../../../assets/ecosystemtreasurybanner.png';
import ecosystemgovernancebanner from '../../../assets/ecosystemgovernancebanner.png';
import { Link } from 'react-router-dom';

const GenEcosystem = () => {
    const theme = useTheme();
    return (
        <>
            <Grid container display="flex" justifyContent="center" mt={15}>
                <Grid container display="flex" lg={9} md={10} sm={11} xs={11.5} ml={4}>
                    <Typography textAlign="center" sx={{ fontSize: 16, color: grey[900], fontWeight: 600 }}>
                        Generic Ecosystem
                    </Typography>
                </Grid>
                <Grid
                    container
                    lg={9}
                    md={10}
                    sm={11}
                    xs={11.5}
                    zIndex={999}
                    display="flex"
                    sx={{ justifyContent: { lg: 'space-between', md: 'space-between', sm: 'center', xs: 'center' } }}
                >
                    <Box
                        sx={{
                            mt: 2,
                            borderRadius: 8,
                            background: '#fff',
                            overflow: 'hidden',
                            position: 'relative',
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                            width: { lg: '33%', md: '33%', sm: '48%', xs: '95%' }
                        }}
                    >
                        <Grid container sx={{ position: 'absolute', width: '50%', top: 0, right: 0, mr: -5, mt: -5 }}>
                            <img src={ecosystemgenbanner} alt="logo" width="100%" />
                        </Grid>
                        <Typography sx={{ fontWeight: 800, fontSize: 24, color: grey[900], mt: 12, px: 3 }}>GEN</Typography>
                        <Typography sx={{ fontSize: 20, fontWeight: 600, color: grey[900], mt: -0.5, px: 3 }}>
                            Store of Value Token
                        </Typography>
                        <Typography sx={{ fontSize: 14, color: grey[900], mt: 1, px: 3 }}>
                            The GEN token is designed as a store of value, enabling stakers to vote or earn consistent rewards.
                        </Typography>
                        <Grid container display="flex" justifyContent="space-between">
                            <Button
                                href="https://aerodrome.finance"
                                target="_blank"
                                sx={{
                                    mt: 6,
                                    mb: 3,
                                    mx: 3,
                                    px: 4,
                                    color: grey[50],
                                    borderRadius: 50,
                                    textTransform: 'none',
                                    background: 'linear-gradient(to right bottom, #070707, #575757)'
                                }}
                            >
                                Buy GEN
                            </Button>
                            <Grid container width="30%" mt={5}>
                                <Typography sx={{ color: grey[500], fontSize: 16 }}>APY</Typography>
                                <Grid container mt={-3.5}>
                                    <Typography sx={{ color: grey[900], fontSize: 18, fontWeight: 900 }}>2007%</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box
                        sx={{
                            mt: 2,
                            borderRadius: 8,
                            background: '#fff',
                            overflow: 'hidden',
                            position: 'relative',
                            ml: { lg: 0, md: 0, sm: 1, xs: 0 },
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                            width: { lg: '33%', md: '33%', sm: '48%', xs: '95%' }
                        }}
                    >
                        <Grid container sx={{ position: 'absolute', width: '50%', top: 0, right: 0, mr: -5, mt: -5 }}>
                            <img src={ecosystemtreasurybanner} alt="logo" width="100%" />
                        </Grid>
                        <Typography sx={{ fontWeight: 800, fontSize: 24, color: grey[900], mt: 12, px: 3 }}>Treasury</Typography>
                        <Typography sx={{ fontSize: 20, fontWeight: 600, color: grey[900], mt: -0.5, px: 3 }}>Asset Manager</Typography>
                        <Typography sx={{ fontSize: 14, color: grey[900], mt: 1, px: 3 }}>
                            The Treasury acts as an asset manager, generating revenue to fuel rewards to stakers.
                        </Typography>
                        <Button
                            href="https://genericdao.gitbook.io/generic"
                            target="_blank"
                            sx={{
                                mt: 6,
                                mb: 3,
                                mx: 3,
                                px: 4,
                                color: grey[50],
                                borderRadius: 50,
                                textTransform: 'none',
                                background: 'linear-gradient(to right bottom, #070707, #575757)'
                            }}
                        >
                            Explore
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            mt: 2,
                            borderRadius: 8,
                            overflow: 'hidden',
                            position: 'relative',
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                            width: { lg: '33%', md: '33%', sm: '50%', xs: '95%' },
                            background: 'linear-gradient(to right bottom, #070707, #292929)'
                        }}
                    >
                        <Grid container sx={{ position: 'absolute', width: '50%', top: 0, right: 0, mr: -5, mt: -5 }}>
                            <img src={ecosystemgovernancebanner} alt="logo" width="100%" />
                        </Grid>
                        <Typography sx={{ fontWeight: 800, fontSize: 24, color: '#fff', mt: 12, px: 3 }}>Governance</Typography>
                        <Typography sx={{ fontSize: 20, fontWeight: 600, color: grey[100], mt: -0.5, px: 3 }}>
                            Generic Improvement Proposals
                        </Typography>
                        <Typography sx={{ fontSize: 14, color: grey[300], mt: 1, px: 3 }}>
                            The protocol allows GEN stakers to vote on transaction fees or any development proposals.
                        </Typography>
                        <Link to="/governance">
                            <Button
                                sx={{
                                    mt: 6,
                                    mb: 3,
                                    mx: 3,
                                    px: 4,
                                    color: grey[900],
                                    borderRadius: 50,
                                    background: grey[50],
                                    textTransform: 'none',
                                    ':hover': { background: grey[50], color: grey[900] }
                                }}
                            >
                                Vote now
                            </Button>
                        </Link>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default GenEcosystem;
