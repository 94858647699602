import { useTheme } from '@mui/system';
import { grey } from '@mui/material/colors';
import InfoIcon from '@mui/icons-material/Info';
import { Button, Chip, Grid, Typography } from '@mui/material';
import genlogo from '../../../assets/genlogo.png';
import btclogo from '../../../assets/bitcoin.png';

const GenStakingStats = () => {
    const theme = useTheme();

    return (
        <>
            <Grid container display="flex" justifyContent="center">
                <Grid
                    container
                    lg={9}
                    md={10}
                    sm={11}
                    xs={11.5}
                    px={5}
                    py={4}
                    mt={2}
                    zIndex={999}
                    display="flex"
                    justifyContent="space-between"
                    sx={{ background: `linear-gradient(to right bottom, #070707, #292929)`, borderRadius: 7 }}
                >
                    <Grid container display="flex" sx={{ width: { lg: '35%', md: '30%', sm: '55%', xs: '100%' } }}>
                        <Grid container display="flex" width="49%">
                            <Typography sx={{ fontSize: 15, color: grey[500] }}>Pending GEN</Typography>
                            <Grid container display="flex" alignItems="center">
                                <img src={genlogo} alt="logo" width={26} height={26} style={{ filter: 'brightness(130%)' }} />
                                <Typography sx={{ fontWeight: 900, fontSize: 25, color: grey[50], ml: 1 }}>0.00</Typography>
                            </Grid>
                            <Typography sx={{ fontWeight: 500, fontSize: 16, color: grey[300], mt: 0.5 }}>$ 0.00</Typography>
                        </Grid>
                        <Grid container display="flex" width="49%">
                            <Typography sx={{ fontSize: 15, color: grey[500] }}>Pending BTC</Typography>
                            <Grid container display="flex" alignItems="center">
                                <img src={btclogo} alt="logo" width={26} height={26} />
                                <Typography sx={{ fontWeight: 900, fontSize: 25, color: grey[50], ml: 1 }}>0.00</Typography>
                            </Grid>
                            <Typography sx={{ fontWeight: 500, fontSize: 16, color: grey[300], mt: 0.5 }}>$ 0.00</Typography>
                        </Grid>
                        <Grid container mt={4}>
                            <Button
                                sx={{
                                    background: grey[50],
                                    borderRadius: 50,
                                    color: grey[900],
                                    textTransform: 'none',
                                    px: 3,
                                    py: 0.5,
                                    fontSize: 15
                                }}
                            >
                                Claim
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        display="flex"
                        sx={{
                            mt: { lg: 0, md: 0, sm: 0, xs: 4 },
                            width: { lg: '63%', md: '69%', sm: '100%', xs: '100%' },
                            justifyContent: { lg: 'right', md: 'right', sm: 'right', xs: 'left' }
                        }}
                        alignItems="center"
                    >
                        <Grid
                            container
                            display="flex"
                            justifyContent="space-between"
                            sx={{ width: { lg: '70%', md: '95%', sm: '100%', xs: '100%' }, mt: { lg: 0, md: 0, sm: 3, xs: 0 } }}
                        >
                            <Grid container width="33%">
                                <Typography sx={{ fontSize: 16, color: grey[400] }}>Total APY</Typography>
                                <Grid container>
                                    <Typography sx={{ color: grey[50], fontWeight: 800, fontSize: 18 }}>2,000% +</Typography>
                                </Grid>
                            </Grid>
                            <Grid container width="33%">
                                <Typography sx={{ fontSize: 16, color: grey[400] }}>GEN APY</Typography>
                                <Grid container display="flex" alignItems="center">
                                    <img src={genlogo} alt="logo" width={18} height={18} />
                                    <Typography sx={{ color: grey[50], fontWeight: 800, fontSize: 18, ml: 0.5, mb: 0.2 }}>
                                        1,987%
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container width="33%">
                                <Typography sx={{ fontSize: 16, color: grey[400] }}>BTC APY</Typography>
                                <Grid container display="flex" alignItems="center">
                                    <img src={btclogo} alt="logo" width={18} height={18} />
                                    <Typography sx={{ color: grey[50], fontWeight: 800, fontSize: 18, ml: 0.5, mb: 0.2 }}>
                                        Flexible
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            sx={{
                                mt: 3,
                                py: 2,
                                px: 3,
                                border: 1,
                                width: '100%',
                                display: 'flex',
                                borderRadius: 3,
                                background: '#101010',
                                borderColor: '#272727',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Grid container sx={{ width: { lg: '50%', md: '57%', sm: '60%', xs: '100%' } }}>
                                <Typography sx={{ fontSize: 14, color: grey[100], fontWeight: 500 }}>GEN Distribution End</Typography>
                                <Chip
                                    label="28 Mar"
                                    size="small"
                                    sx={{ px: 0.5, background: '#262525', border: 1, borderColor: '#343434', ml: 2 }}
                                />
                                <Grid container sx={{ mt: { lg: 0, md: 0, sm: 0, xs: 1 }, pr: { lg: 4, md: 4, sm: 4, xs: 2 } }}>
                                    <Typography sx={{ fontSize: 12, color: grey[100] }}>
                                        Stake GEN to earn protocol revenue in GEN and BTC distributed instantly.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                borderColor={grey[800]}
                                sx={{
                                    mt: { lg: 0, md: 0, sm: 0, xs: 2 },
                                    pl: { lg: 3, md: 3, sm: 3, xs: 0 },
                                    borderLeft: { lg: 1, md: 1, sm: 1, xs: 0 },
                                    width: { lg: '50%', md: '42%', sm: '40%', xs: '100%' }
                                }}
                            >
                                <Typography sx={{ fontSize: 16, color: grey[300], ml: 0.3 }}>Distributed Tokens</Typography>
                                <Grid container display="flex" alignItems="center">
                                    <img src={genlogo} alt="logo" width={25} height={25} />
                                    <Typography
                                        sx={{ fontSize: { lg: 20, md: 18, sm: 18, xs: 18 }, color: grey[50], ml: 1, fontWeight: 900 }}
                                    >
                                        0.00
                                    </Typography>
                                    <Typography
                                        sx={{ fontSize: { lg: 20, md: 18, sm: 18, xs: 18 }, color: grey[500], ml: 1, fontWeight: 900 }}
                                    >
                                        / 10.0M
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default GenStakingStats;
