import '../BlinkingText.css';
import { useState } from 'react';
import { useTheme } from '@mui/system';
import MyRadioGroup from './RadioGroupFIP';
import { grey } from '@mui/material/colors';
import { useWeb3React } from '@web3-react/core';
import bg from '../../../assets/DashboardBanner.png';
import { Grid, Typography, Button } from '@mui/material';

const GovernanceActiveCard = () => {
    const theme = useTheme();
    const { active, account } = useWeb3React();
    const [currentVotes] = useState([0, 0, 0]);
    const [availableToVote, setAvailableToVote] = useState('0.00');
    const [vote1Fee, vote2Fee, vote5Fee] = currentVotes;
    const [setCurrentOption] = useState(1);
    const handleValueChange = (newValue) => {
        setCurrentOption(newValue);
    };

    return (
        <>
            <Grid
                item
                sx={{
                    position: 'absolute', // Stays behind content
                    top: 0,
                    left: 0,
                    justifyContent: 'center',
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden'
                }}
            >
                <img
                    src={bg} // Path to your background image
                    alt="Background"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover', // Ensures the image covers the entire container
                        filter: 'brightness(93%)'
                    }}
                />
            </Grid>
            <Grid
                lg={9}
                md={10}
                sm={11}
                xs={11.5}
                container
                sx={{
                    p: 3,
                    zIndex: 999,
                    display: 'flex',
                    borderRadius: 5,
                    justifyContent: 'left',
                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
                    background: 'linear-gradient(to right bottom, #070707, #272727)'
                }}
            >
                <Typography sx={{ color: grey[50], fontWeight: 600, fontSize: 18 }}>#1 Generic Improvement Proposals</Typography>
                <Grid container>
                    <Typography sx={{ color: grey[300], fontSize: 16 }}>Adjust the transaction fee of GEN tokens</Typography>
                </Grid>
                <Grid container mx={1} my={1}>
                    <MyRadioGroup vote1={vote1Fee} vote2={vote2Fee} vote3={vote5Fee} onValueChange={handleValueChange} />
                </Grid>
                <Button
                    fullWidth
                    sx={{
                        mt: 2,
                        color: grey[900],
                        borderRadius: 50,
                        background: grey[50],
                        textTransform: 'none',
                        ':hover': { background: grey[50], color: grey[900] }
                    }}
                >
                    {active ? 'Vote' : 'Connect wallet'}
                </Button>
            </Grid>
        </>
    );
};

export default GovernanceActiveCard;
