import { Typography, Modal, Box, Button, Grid } from '@mui/material';
import { grey } from '@mui/material/colors';
import PropTypes from 'prop-types';

// ==============================|| TransactionModal ||============================== //

const PopUpInfo = ({ open, setOpen, title, text }) => {
    const handleClose = () => setOpen(false);

    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '90%', sm: '70%', md: '40%', lg: '30%' },
                    backdropFilter: 'blur(10px)',
                    bgcolor: 'rgba(34, 34, 34, 0.8)',
                    border: 0,
                    borderRadius: 4,
                    boxShadow: 'inset 0px 0px 20px ',
                    p: 4
                }}
            >
                <Grid container sx={{ display: 'flex' }}>
                    <Typography sx={{ textAlign: 'left', fontSize: 20, color: grey[100], fontWeight: 500 }}>{title}</Typography>
                </Grid>
                <Grid container sx={{ display: 'flex', mt: 1 }}>
                    <Typography sx={{ textAlign: 'left', fontSize: 16, color: grey[400] }}>{text}</Typography>
                </Grid>
                <Grid container sx={{ display: 'flex', mt: 2 }}>
                    <Button
                        onClick={() => {
                            handleClose();
                        }}
                        sx={{
                            background: '#fff',
                            textTransform: 'none',
                            borderRadius: 50,
                            width: { lg: '40%', md: '45%', sm: '100%', xs: '100%' },
                            color: grey[900],
                            ':disabled': {
                                background: grey[600],
                                color: grey[100]
                            },
                            ':hover': { background: '#fff', color: grey[900] }
                        }}
                    >
                        Got it, thanks!
                    </Button>
                </Grid>
            </Box>
        </Modal>
    );
};

PopUpInfo.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired
};

export default PopUpInfo;
