import Web3 from 'web3';
import { useState, useEffect } from 'react';
import { grey } from '@mui/material/colors';
import { useWeb3React } from '@web3-react/core';
import usdclogo from '../../../assets/usdc3d.png';
import PopUpInfo from 'ui-component/PopUpInfoModal';
import btclogo from '../../../assets/bitcoinsvg.svg';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import TransactionModal from 'ui-component/SuccessfulTransactionModal';
import RejectedTransactionModal from 'ui-component/RejectedTransactionModal';
import SubmittedTransactionModal from 'ui-component/SubmittedTransactionModal';
import { Grid, Typography, Button, styled, TextField, IconButton } from '@mui/material';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import {
    approveGENManager,
    balanceOfGEN,
    claimRewards,
    fetchGENPrice,
    fetchRewards,
    fetchStakedBalance,
    fetchWBTCRewards,
    stake
} from 'components/wallet/sharesABI';
import { brandColor } from 'themes/constants';

const CustomTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        backgroundColor: 'transparent',
        border: 'none',
        textAlign: 'right', // Align text to the right
        color: grey[100],
        fontSize: 20,
        width: 'auto',
        fontWeight: 500
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:after': {
        borderBottom: 'none'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 'none'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'transparent'
        },
        '&:hover fieldset': {
            borderColor: 'transparent'
        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent'
        },
        '& .MuiOutlinedInput-input': {
            textAlign: 'right', // Ensure input text aligns right
            '&::placeholder': {
                fontSize: '16px', // Custom font size for placeholder
                color: 'rgba(0, 0, 0, 0.6)' // Custom color for placeholder
            }
        },
        '& .MuiInputLabel-outlined': {
            textAlign: 'right' // Align label to the right
        }
    }
});

const UsdcStakingCard = () => {
    const web3 = new Web3(window.ethereum);
    const [rewards, setRewards] = useState(0);
    const { BN, toWei, fromWei } = web3.utils;
    const { active, account } = useWeb3React();
    const [popText, setPopText] = useState('');
    const [genPrice, setGenPrice] = useState('');
    const [loading, setLoading] = useState(true);
    const [inputValue, setInputValue] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [wbtcRewards, setWbtcRewards] = useState(0);
    const [stakingValue, setStakingValue] = useState(0);
    const [stakedBalance, setStakedBalance] = useState(0);
    const [userGENBalance, setUserGENBalance] = useState(0);
    const [transactionHash, setTransactionHash] = useState('');
    const [modalPopUpOpen, setModalPopUpOpen] = useState(false);
    const [modalRejection, setModalRejection] = useState(false);
    const [modalSubmitted, setModalSubmitted] = useState(false);
    const price = genPrice === '' ? 0 : genPrice[1] / genPrice[0];

    const handleInputChange = (event) => {
        if (active && account) {
            let value = event.target.value;

            // Remove any non-digit and non-decimal characters except the first dot
            value = value.replace(/[^\d.]/g, '');

            // Ensure only one decimal point is present
            const decimalCount = value.split('.').length - 1;
            if (decimalCount > 1) {
                value = value.slice(0, value.lastIndexOf('.'));
            }

            if (value > userGENBalance / 10 ** 18) {
                setInputValue(userGENBalance / 10 ** 18);
                const tempBalance = fromWei(userGENBalance, 'ether');
                value = tempBalance;
            } else {
                setInputValue(value);
            }

            if (value) {
                const amountInWei = toWei(value, 'ether');
                const tempAmountBN = new BN(amountInWei); // Create BN object from sanitized value
                setStakingValue(tempAmountBN); // Update amountBN state
            } else {
                setStakingValue(new BN(0)); // If input value is empty, set amountBN to 0
            }
        }
    };

    const updateData = async () => {
        try {
            const balanceOf = await balanceOfGEN(account);
            const tmpStakedBalance = await fetchStakedBalance(account);
            setUserGENBalance(balanceOf);
            setStakedBalance(tmpStakedBalance);
        } catch (error) {
            console.error('Error fetching rewards:', error);
        }
    };

    const handleStake = async () => {
        if (!stakingValue || Number(stakingValue) <= 0) {
            alert('Please enter a valid staking amount.');
            return;
        }

        try {
            await stake(stakingValue, (hash) => {
                // Update transaction hash when transaction is submitted
                setTransactionHash(hash);
                updateData();
            });
        } catch (error) {
            console.error('Error staking:', error);
            // Handle error as needed (e.g., show an error message)
        } finally {
            setLoading(false);
        }
    };

    const handleApprove = async () => {
        try {
            await approveGENManager(0, (hash) => {
                // Update transaction hash when transaction is submitted
                setTransactionHash(hash);
                updateData();
            });
        } catch (error) {
            console.error('Error staking:', error);
            // Handle error as needed (e.g., show an error message)
        } finally {
            setLoading(false);
        }
    };

    const handleClaimRewards = async () => {
        setLoading(true);
        try {
            await claimRewards((hash) => {
                // Update transaction hash when the transaction is submitted
                setTransactionHash(hash);
                updateData();
            });
        } catch (error) {
            console.error('Error claiming rewards:', error);
            // Handle error as needed (e.g., show an error message)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // Define the function to fetch rewards and update state
        const updateData = async () => {
            try {
                const tmpGENPrice = await fetchGENPrice();
                const balanceOf = await balanceOfGEN(account);
                const pendingRewards = await fetchRewards(account);
                const pendingWBTCRewards = await fetchWBTCRewards(account);
                const tmpStakedBalance = await fetchStakedBalance(account);
                setLoading(false);
                setGenPrice(tmpGENPrice);
                setRewards(pendingRewards);
                setUserGENBalance(balanceOf);
                setWbtcRewards(pendingWBTCRewards);
                setStakedBalance(tmpStakedBalance);
            } catch (error) {
                setLoading(false);
                console.error('Error fetching rewards:', error);
            }
        };

        // Call updateRewards every 3 seconds
        const intervalId = setInterval(updateData, 3000);

        // Fetch immediately on component mount
        updateData();

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, [account]);

    return (
        <>
            <Grid
                container
                sx={{
                    p: 3,
                    mt: 1,
                    mx: 1,
                    zIndex: 999,
                    display: 'flex',
                    borderRadius: 5,
                    mb: { lg: 0, md: 0, sm: -1, xs: -1 },
                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
                    width: { lg: 450, md: 450, sm: '91%', xs: '100%' },
                    background: 'linear-gradient(to right bottom, #070707, #292929)'
                }}
            >
                <Grid container display="flex">
                    <img src={usdclogo} alt="logo" width={35} height={35} />
                    <Typography sx={{ color: grey[100], fontWeight: 700, fontSize: 22, ml: 1 }}>USDC Pool</Typography>
                </Grid>
                {/* INPUT CARD */}
                <Grid
                    container
                    mt={1}
                    px={2}
                    py={1}
                    border={1}
                    display="flex"
                    borderRadius={5}
                    bgcolor="#171717"
                    alignItems="center"
                    borderColor="#272727"
                >
                    <Grid container display="flex" justifyContent="space-between" alignItems="center">
                        <Typography sx={{ color: grey[300], fontSize: 15 }}>Amount</Typography>
                        <Button
                            onClick={() => {
                                setInputValue(userGENBalance / 10 ** 18);
                                setStakingValue(stakedBalance);
                            }}
                            sx={{
                                bgcolor: 'transparent',
                                color: grey[200],
                                fontSize: 15,
                                textTransform: 'none'
                            }}
                        >
                            Balance: {(userGENBalance / 10 ** 18).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                        </Button>
                    </Grid>
                    <Grid container display="flex" justifyContent="space-between" alignItems="center">
                        <Grid container width="35%" display="flex" alignItems="center">
                            <img src={usdclogo} alt="logo" width={25} />
                            <Typography sx={{ color: grey[100], fontSize: 20, fontWeight: 500, pl: 0.75 }}>USDC</Typography>
                        </Grid>
                        <Grid container width="60%" display="flex" justifyContent="right" alignItems="center">
                            <CustomTextField style={{ fontSize: 32 }} onChange={handleInputChange} value={inputValue} />
                        </Grid>
                    </Grid>
                    <Grid container display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                        <Typography sx={{ color: grey[300], fontSize: 15 }}>USD Coin</Typography>
                        <Typography sx={{ color: grey[400], fontSize: 15 }}>
                            ~$
                            {(inputValue * price).toLocaleString('en-US', {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                            })}
                        </Typography>
                    </Grid>
                </Grid>
                {/* REWARDS CARD */}
                <Grid
                    container
                    p={2}
                    mt={3}
                    display="flex"
                    justifyContent="center"
                    sx={{
                        border: 1,
                        borderRadius: 5,
                        background: '#171717',
                        borderColor: '#272727',
                        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'
                    }}
                >
                    <Grid container display="flex" justifyContent="space-between">
                        <Typography sx={{ color: grey[400], fontSize: 12, fontWeight: 500 }}>APR:</Typography>
                        <Typography sx={{ color: grey[300], fontSize: 15, fontWeight: 700 }}>Flexible</Typography>
                    </Grid>
                    <Grid container display="flex" justifyContent="space-between" mt={1}>
                        <Typography sx={{ color: grey[400], fontSize: 12, fontWeight: 500 }}>USDC Staked:</Typography>
                        <Typography sx={{ color: grey[300], fontSize: 15, fontWeight: 700 }}>0.00</Typography>
                    </Grid>
                    <Grid container display="flex" justifyContent="space-between" mt={1}>
                        <Typography sx={{ color: grey[400], fontSize: 12, fontWeight: 500 }}>Stake Fee:</Typography>
                        <Typography sx={{ color: grey[300], fontSize: 15, fontWeight: 700 }}>0.00%</Typography>
                    </Grid>
                    <Grid container display="flex" justifyContent="space-between" my={1}>
                        <Typography sx={{ color: grey[400], fontSize: 12, fontWeight: 500 }}>Claim Fee:</Typography>
                        <Typography sx={{ color: grey[300], fontSize: 15, fontWeight: 700 }}>0.00%</Typography>
                    </Grid>
                    <Grid container display="flex" justifyContent="space-between">
                        <Grid container sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                            <Typography sx={{ color: grey[400], fontWeight: 500, fontSize: 12 }}>WBTC rewards</Typography>
                            <Typography
                                sx={{
                                    mt: 0.25,
                                    ml: 0.25,
                                    fontSize: 15,
                                    color: grey[100],
                                    fontWeight: 700,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                {(wbtcRewards / 10 ** 18).toLocaleString('en-US', { maximumFractionDigits: 6, minimumFractionDigits: 4 })}
                                <img src={btclogo} alt="logo" width={17} height={17} style={{ marginLeft: 3 }} />
                            </Typography>
                            <Typography sx={{ color: grey[400], fontWeight: 600, fontSize: 12, ml: 0.25, mt: -0.5 }}>($0.00)</Typography>
                        </Grid>
                        <Grid container sx={{ display: 'flex', flexDirection: 'column', width: '50%', justifyContent: 'right' }}>
                            <Typography textAlign="right" sx={{ color: grey[400], fontWeight: 500, fontSize: 12 }}>
                                USDC Staked
                            </Typography>
                            <Typography
                                sx={{
                                    ml: 0.25,
                                    mt: 0.25,
                                    fontSize: 15,
                                    color: grey[100],
                                    fontWeight: 700,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'right'
                                }}
                            >
                                <img src={usdclogo} alt="logo" width={18} height={18} style={{ marginRight: 5 }} />
                                {(rewards / 10 ** 18).toLocaleString('en-US', { maximumFractionDigits: 6, minimumFractionDigits: 2 })}
                                <IconButton sx={{ width: 20, height: 20 }}>
                                    <RemoveCircleIcon sx={{ color: brandColor, fontSize: 18, ml: 0.5 }} />
                                </IconButton>
                            </Typography>
                            <Typography textAlign="right" sx={{ color: grey[400], fontWeight: 600, fontSize: 12, mt: -0.5 }}>
                                ($
                                {((price * rewards) / 10 ** 18).toLocaleString('en-US', {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                })}
                                )
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container display="flex" justifyContent="space-between" mt={2}>
                        <Grid container sx={{ display: 'flex', alignItems: 'center', width: 'auto' }}>
                            <Button
                                disabled={!active}
                                onClick={() => {
                                    handleClaimRewards();
                                }}
                                sx={{
                                    px: 2,
                                    color: '#fff',
                                    borderRadius: 50,
                                    bgcolor: '#0e121c',
                                    textTransform: 'none',
                                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
                                    ':disabled': { bgcolor: grey[800], color: grey[100] },
                                    ':hover': { bgcolor: '#0e121c', color: '#fff', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)' }
                                }}
                            >
                                Claim
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container mt={3}>
                    {account && active ? (
                        <>
                            <Button
                                fullWidth
                                onClick={() => {
                                    handleStake();
                                }}
                                disabled={inputValue === 0}
                                sx={{
                                    py: 1,
                                    fontSize: 18,
                                    borderRadius: 50,
                                    color: grey[200],
                                    textTransform: 'none',
                                    ':disabled': {
                                        background: grey[500],
                                        color: grey[100]
                                    },
                                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
                                    background: `linear-gradient(45deg, #EB4308, #f9774b)`
                                }}
                            >
                                {inputValue === 0 ? 'Enter Amount' : 'Stake'}
                            </Button>
                        </>
                    ) : (
                        <Button
                            fullWidth
                            startIcon={<AccountBalanceWalletOutlinedIcon />}
                            sx={{
                                py: 1,
                                height: 50,
                                borderRadius: 50,
                                color: grey[900],
                                background: '#fff',
                                textTransform: 'none',
                                fontSize: 15,
                                ':disabled': {
                                    background: grey[600],
                                    color: grey[100]
                                },
                                ':hover': {
                                    background: '#fff',
                                    color: grey[900]
                                }
                            }}
                        >
                            Connect wallet
                        </Button>
                    )}
                </Grid>
            </Grid>
            <PopUpInfo open={modalPopUpOpen} setOpen={setModalPopUpOpen} text={popText} />
            <RejectedTransactionModal open={modalRejection} setOpen={setModalRejection} />
            <SubmittedTransactionModal open={modalSubmitted} setOpen={setModalSubmitted} />
            <TransactionModal open={modalOpen} setOpen={setModalOpen} txnhash={transactionHash} />
        </>
    );
};

export default UsdcStakingCard;
