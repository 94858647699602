import { useState } from 'react';
import { grey } from '@mui/material/colors';
import { Typography, Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const MyRadioGroup = ({ vote1, vote2, vote3 }) => {
    const [selected, setSelected] = useState(0);

    return (
        <Grid container display="flex" mt={2} sx={{ justifyContent: 'space-between' }}>
            <Grid
                onClick={() => {
                    setSelected(0);
                }}
                container
                sx={{
                    background: selected === 0 ? '#505050' : '#282828',
                    display: 'flex',
                    justifyContent: 'space-between',
                    p: 2,
                    borderRadius: 3,
                    width: { lg: '31%', md: '31%', sm: '100%', xs: '100%' },
                    ':hover': { cursor: 'pointer' }
                }}
            >
                <Grid container width="85%">
                    <Typography sx={{ color: grey[100], fontWeight: 600, fontSize: 16 }}>Light ({vote1} votes)</Typography>
                    <Grid container display="flex">
                        <Typography sx={{ color: grey[400], fontSize: 14 }}>1% Fee</Typography>
                        <Typography sx={{ color: grey[400], fontSize: 14, mx: 1.5 }}>·</Typography>
                        <Typography sx={{ color: grey[400], fontSize: 14 }}>Trade-Friendly</Typography>
                    </Grid>
                </Grid>
                <Grid container width="auto" alignItems="center" sx={{ display: selected === 0 ? 'flex' : 'none' }}>
                    <CheckCircleIcon sx={{ fontSize: 20 }} />
                </Grid>
            </Grid>
            <Grid
                onClick={() => {
                    setSelected(1);
                }}
                container
                sx={{
                    p: 2,
                    display: 'flex',
                    borderRadius: 3,
                    justifyContent: 'space-between',
                    my: { lg: 0, md: 0, sm: 1, xs: 1 },
                    mx: { lg: 1, md: 1, sm: 0, xs: 0 },
                    background: selected === 1 ? '#505050' : '#282828',
                    width: { lg: '31%', md: '31%', sm: '100%', xs: '100%' },
                    ':hover': { cursor: 'pointer' }
                }}
            >
                <Grid container width="85%">
                    <Typography sx={{ color: grey[100], fontWeight: 600, fontSize: 16 }}>Standard ({vote2} votes)</Typography>
                    <Grid container display="flex">
                        <Typography sx={{ color: grey[400], fontSize: 14 }}>2% Fee</Typography>
                        <Typography sx={{ color: grey[400], fontSize: 14, mx: 1.5 }}>·</Typography>
                        <Typography sx={{ color: grey[400], fontSize: 14 }}>Balanced Approach</Typography>
                    </Grid>
                </Grid>
                <Grid container width="auto" alignItems="center" sx={{ display: selected === 1 ? 'flex' : 'none' }}>
                    <CheckCircleIcon sx={{ fontSize: 20 }} />
                </Grid>
            </Grid>
            <Grid
                onClick={() => {
                    setSelected(2);
                }}
                container
                sx={{
                    p: 2,
                    display: 'flex',
                    borderRadius: 3,
                    justifyContent: 'space-between',
                    background: selected === 2 ? '#505050' : '#282828',
                    width: { lg: '31%', md: '31%', sm: '100%', xs: '100%' },
                    ':hover': { cursor: 'pointer' }
                }}
            >
                <Grid container width="85%">
                    <Typography sx={{ color: grey[100], fontWeight: 600, fontSize: 16 }}>Growth ({vote3} votes)</Typography>
                    <Grid container display="flex">
                        <Typography sx={{ color: grey[400], fontSize: 14 }}>3% Fee</Typography>
                        <Typography sx={{ color: grey[400], fontSize: 14, mx: 1.5 }}>·</Typography>
                        <Typography sx={{ color: grey[400], fontSize: 14 }}>Growth and Revenue</Typography>
                    </Grid>
                </Grid>
                <Grid container width="auto" alignItems="center" sx={{ display: selected === 2 ? 'flex' : 'none' }}>
                    <CheckCircleIcon sx={{ fontSize: 20 }} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default MyRadioGroup;
