import { useEffect } from 'react';
import { Grid } from '@mui/material';
import GovernanceStats from './GovernanceStats';
import bg from '../../../assets/DashboardBanner.png';
import GovernanceActiveCard from './GovernanceActiveCard';

const Governance = () => {
    useEffect(() => {
        document.title = 'Governance | Generic';
    });
    return (
        <>
            <Grid container display="flex" justifyContent="center" mt={20}>
                <GovernanceActiveCard />
            </Grid>
            <Grid container display="flex" justifyContent="center" mt={-5}>
                <GovernanceStats />
            </Grid>
        </>
    );
};

export default Governance;
