import { FaCaretUp } from 'react-icons/fa';
import { grey } from '@mui/material/colors';
import { useTheme, styled } from '@mui/system';
import InfoIcon from '@mui/icons-material/Info';
import genlogo from '../../../assets/genlogo.png';
import CircleIcon from '@mui/icons-material/Circle';
import { LinearProgress, Grid, Typography, Box } from '@mui/material';

const GradientProgressBar = styled(LinearProgress)(({ theme }) => ({
    height: 10, // Unfilled part height
    borderRadius: 3,
    backgroundColor: grey[800],
    position: 'relative',
    overflow: 'hidden',
    '& .MuiLinearProgress-bar': {
        background: 'linear-gradient(to right, #ff6a00, #ee0979)', // Gradient for filled part
        boxShadow: '0 4px 20px rgba(238, 9, 121, 0.8)', // Adds shadow to make it stand out
        borderRadius: 8 // Rounded edges for the filled part
    }
}));

const GovernanceStats = () => {
    const theme = useTheme();

    return (
        <>
            <Grid container display="flex" justifyContent="center" mt={5}>
                <Grid
                    container
                    lg={9}
                    md={10}
                    sm={11}
                    xs={11.5}
                    mt={2}
                    zIndex={999}
                    display="flex"
                    justifyContent="space-between"
                    sx={{
                        zIndex: 999,
                        borderRadius: 7,
                        overflow: 'hidden',
                        px: { lg: 5, md: 5, sm: 4, xs: 2 },
                        background: `linear-gradient(to right bottom, #141414, #141414)`
                    }}
                >
                    <Grid container lg={6.5} display="flex" alignItems="center" pt={4} sx={{ px: { lg: 0, md: 0, sm: 0, xs: 3 } }}>
                        <img src={genlogo} alt="logo" height={30} width={30} />
                        <Typography sx={{ color: grey[50], fontWeight: 700, fontSize: { lg: 20, md: 20, sm: 20, xs: 18 }, ml: 1 }}>
                            Generic Improvement Proposals
                        </Typography>
                        <Grid container width="100%" mt={2}>
                            <Typography sx={{ color: grey[200], fontSize: 16 }}>
                                Generic empowers $GEN stakers to actively participate in governance by proposing and voting on changes to
                                key parameters, such as fees and reward distribution proportions. This decentralized approach ensures the
                                platform remains adaptable, fair, and aligned with the community’s interests.
                            </Typography>
                        </Grid>
                        <Grid container width="100%" my={4} sx={{ ml: { lg: 2, md: 2, sm: 2, xs: 0 } }} display="flex">
                            <Grid
                                container
                                display="flex"
                                flexDirection="column"
                                sx={{
                                    borderLeft: { lg: 0, md: 0, sm: 0, xs: 1 },
                                    borderColor: { xs: grey[800] },
                                    pl: { lg: 0, md: 0, sm: 0, xs: 2 },
                                    width: { lg: 'auto', md: 'auto', sm: 'auto', xs: '100%' }
                                }}
                            >
                                <Typography sx={{ color: grey[500], fontWeight: 800, fontSize: 16 }}>Eligible Votes</Typography>
                                <Typography sx={{ color: grey[50], fontSize: 16 }}>361.08K</Typography>
                            </Grid>
                            <Grid
                                container
                                display="flex"
                                flexDirection="column"
                                sx={{
                                    my: { lg: 0, md: 0, sm: 0, xs: 2 },
                                    pl: { lg: 4, md: 4, sm: 4, xs: 2 },
                                    pr: { lg: 4, md: 4, sm: 4, xs: 0 },
                                    mx: { lg: 4, md: 4, sm: 4, xs: 0 },
                                    borderLeft: { lg: 1.3, md: 1.3, sm: 1.3, xs: 1 },
                                    borderRight: { lg: 1.3, md: 1.3, sm: 1.3, xs: 0 },
                                    borderColor: { xs: grey[800], lg: grey[800], sm: grey[800], md: grey[800] },
                                    width: { lg: 'auto', md: 'auto', sm: 'auto', xs: '100%' }
                                }}
                            >
                                <Typography sx={{ color: grey[500], fontWeight: 800, fontSize: 16, display: 'flex', alignItems: 'center' }}>
                                    RTP* <InfoIcon sx={{ fontSize: 14, ml: 0.5 }} />
                                </Typography>
                                <Typography sx={{ color: grey[50], fontSize: 16 }}>100.00K</Typography>
                            </Grid>
                            <Grid
                                container
                                display="flex"
                                flexDirection="column"
                                sx={{
                                    borderLeft: { lg: 0, md: 0, sm: 0, xs: 1 },
                                    borderColor: { xs: grey[800] },
                                    pl: { lg: 0, md: 0, sm: 0, xs: 2 },
                                    width: { lg: 'auto', md: 'auto', sm: 'auto', xs: '100%' }
                                }}
                            >
                                <Typography sx={{ color: grey[500], fontWeight: 800, fontSize: 16 }}>$GEN Txn Fee</Typography>
                                <Typography sx={{ color: grey[50], fontSize: 16 }}>2%</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        sx={{
                            mt: -4,
                            width: { lg: 300 },
                            height: 300,
                            overflow: 'hidden',
                            position: 'relative',
                            display: { lg: 'flex', md: 'none', sm: 'none', xs: 'none' }
                        }}
                    >
                        <img
                            src={genlogo}
                            alt="logo"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover', // Ensures the image scales properly
                                position: 'absolute', // Stretches the image to the container
                                top: 0,
                                left: 0
                            }}
                        />
                        <div
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                background: 'linear-gradient(to top, #141414, transparent)', // Black to transparent gradient
                                pointerEvents: 'none' // Ensures the gradient doesn’t block interactions
                            }}
                        />
                    </Grid>
                    <Grid container display="flex" justifyContent="center" mt={2} sx={{ mx: { lg: 0, md: 0, sm: 0, xs: 3 } }}>
                        <Box width="100%">
                            <GradientProgressBar variant="determinate" value={63} />
                        </Box>
                        <Grid container display="flex" justifyContent="space-between" mt={2}>
                            <Typography sx={{ color: grey[200], fontSize: 16 }}>63% Staked</Typography>
                            <Typography sx={{ color: grey[200], fontSize: 16 }}>37% Circulating</Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        display="flex"
                        mt={4}
                        sx={{
                            mb: { lg: 4.5, md: 4.5, sm: 3.5, xs: 2 },
                            justifyContent: { lg: 'space-between', md: 'space-between', sm: 'center', xs: 'center' }
                        }}
                    >
                        <Box
                            sx={{
                                borderRadius: 4,
                                background: '#222222',
                                mr: { lg: 0, md: 0, sm: 1, xs: 0 },
                                width: { lg: '33%', md: '33%', sm: '49%', xs: '100%' },
                                px: 3,
                                py: 2.5
                            }}
                        >
                            <Typography sx={{ color: grey[50], fontWeight: 700, fontSize: 22 }}>Real Ownership</Typography>
                            <Typography sx={{ color: grey[400], fontSize: 15, mt: 1 }}>
                                GEN token stakers gain governance rights and are eligible to use the Generic Improvement Proposals (GIP),
                                enabling them to influence fees and make key decisions about the protocol’s developments.
                            </Typography>
                            <Grid container display="flex" justifyContent="space-between" mt={6} mb={1}>
                                <Typography sx={{ color: grey[50], fontWeight: 600, fontSize: 16 }}>Transaction Fee</Typography>
                                <Typography sx={{ color: grey[50], fontWeight: 600, fontSize: 16, display: 'flex', alignItems: 'center' }}>
                                    <FaCaretUp style={{ fontSize: 20, color: '#d50000', marginRight: 5 }} /> 2.00%
                                </Typography>
                            </Grid>
                        </Box>
                        <Box
                            sx={{
                                px: 3,
                                py: 2.5,
                                borderRadius: 4,
                                background: '#222222',
                                my: { lg: 0, md: 0, sm: 0, xs: 1 },
                                width: { lg: '33%', md: '33%', sm: '49%', xs: '100%' }
                            }}
                        >
                            <Typography sx={{ color: grey[50], fontWeight: 700, fontSize: 22 }}>Community Influence</Typography>
                            <Typography sx={{ color: grey[400], fontSize: 15, mt: 1 }}>
                                Generic lets stakers propose changes like adjusting fees or reward distribution and vote on future
                                developments, ensuring the platform evolves with the community’s vision.
                            </Typography>
                            <Grid container display="flex" justifyContent="space-between" mt={6} mb={1}>
                                <Typography sx={{ color: grey[50], fontWeight: 600, fontSize: 16 }}>RTP*</Typography>
                                <Typography sx={{ color: grey[50], fontWeight: 600, fontSize: 16, display: 'flex', alignItems: 'center' }}>
                                    <FaCaretUp style={{ fontSize: 20, color: '#00e676', marginRight: 5 }} /> 100.00K GEN
                                </Typography>
                            </Grid>
                        </Box>
                        <Box
                            sx={{
                                borderRadius: 4,
                                background: '#222222',
                                mt: { lg: 0, md: 0, sm: 1, xs: 0 },
                                width: { lg: '33%', md: '33%', sm: '50%', xs: '100%' },
                                px: 3,
                                py: 2.5
                            }}
                        >
                            <Typography sx={{ color: grey[50], fontWeight: 700, fontSize: 22 }}>On-Chain Transparency</Typography>
                            <Typography sx={{ color: grey[400], fontSize: 15, mt: 1 }}>
                                Proposals and votes are stored on the blockchain, guaranteeing transparency and creating a verifiable
                                history that reinforces trust in the governance process.
                            </Typography>
                            <Grid container display="flex" justifyContent="space-between" mt={6} mb={1}>
                                <Typography sx={{ color: grey[50], fontWeight: 600, fontSize: 16 }}>Network</Typography>
                                <Typography sx={{ color: grey[50], fontWeight: 600, fontSize: 16, display: 'flex', alignItems: 'center' }}>
                                    <CircleIcon style={{ fontSize: 20, color: '#00e676', marginRight: 5 }} /> BASE
                                </Typography>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default GovernanceStats;
