import XIcon from '@mui/icons-material/X';
import { grey } from '@mui/material/colors';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, IconButton, Typography } from '@mui/material';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <>
            <Grid
                container
                sx={{
                    display: 'flex',
                    justifyContent: { lg: 'space-around', md: 'space-around', sm: 'center', xs: 'center ' },
                    mt: 10,
                    mb: 5
                }}
            >
                <Grid
                    container
                    sx={{ display: 'flex', width: { lg: 'auto', md: 'auto', sm: '100%', xs: '100%' }, justifyContent: 'center' }}
                >
                    <IconButton
                        href="https://t.me/genericprotocol"
                        size="medium"
                        sx={{ background: 'linear-gradient(to right bottom, #000, #4C4C4C)', boxShadow: 1, width: 30, height: 30 }}
                    >
                        <TelegramIcon sx={{ color: grey[50], fontSize: 18 }} />
                    </IconButton>
                    <IconButton
                        href="https://x.com/genericprotocol"
                        size="medium"
                        sx={{ background: 'linear-gradient(to right bottom, #000, #4C4C4C)', ml: 2, boxShadow: 1, width: 30, height: 30 }}
                    >
                        <XIcon sx={{ color: grey[50], fontSize: 16 }} />
                    </IconButton>
                </Grid>
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        width: { lg: 'auto', md: 'auto', sm: '100%', xs: '100%' },
                        justifyContent: 'center',
                        mt: { lg: 0, md: 0, sm: 2, xs: 2 },
                        mb: { lg: 0, md: 0, sm: 1, xs: 1 }
                    }}
                >
                    <Button
                        target="_blank"
                        href="https://genericdao.gitbook.io/generic"
                        sx={{ fontSize: 16, color: grey[900], fontWeight: 500, mx: 3, background: 'transparent', textTransform: 'none' }}
                    >
                        Documentation
                    </Button>
                    <Button
                        target="_blank"
                        href="https://genericdao.gitbook.io/generic/troubleshooting-and-support/faq"
                        sx={{ fontSize: 16, color: grey[900], fontWeight: 500, mx: 3, background: 'transparent', textTransform: 'none' }}
                    >
                        FAQ
                    </Button>
                </Grid>
                <Grid container sx={{ display: 'flex', width: 'auto' }}>
                    <Typography sx={{ fontSize: 16, color: grey[900], fontWeight: 500 }}>@Generic {currentYear}</Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default Footer;
